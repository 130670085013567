exports.components = {
  "component---src-pages-bolsas-js": () => import("./../../../src/pages/bolsas.js" /* webpackChunkName: "component---src-pages-bolsas-js" */),
  "component---src-pages-comissaodepais-js": () => import("./../../../src/pages/comissaodepais.js" /* webpackChunkName: "component---src-pages-comissaodepais-js" */),
  "component---src-pages-escoladepais-js": () => import("./../../../src/pages/escoladepais.js" /* webpackChunkName: "component---src-pages-escoladepais-js" */),
  "component---src-pages-eventoenem-js": () => import("./../../../src/pages/eventoenem.js" /* webpackChunkName: "component---src-pages-eventoenem-js" */),
  "component---src-pages-formulario-bolsa-js": () => import("./../../../src/pages/formulario-bolsa.js" /* webpackChunkName: "component---src-pages-formulario-bolsa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscricoesoba-js": () => import("./../../../src/pages/inscricoesoba.js" /* webpackChunkName: "component---src-pages-inscricoesoba-js" */),
  "component---src-pages-pesquisadeavaliacao-js": () => import("./../../../src/pages/pesquisadeavaliacao.js" /* webpackChunkName: "component---src-pages-pesquisadeavaliacao-js" */),
  "component---src-pages-seriosacademy-js": () => import("./../../../src/pages/seriosacademy.js" /* webpackChunkName: "component---src-pages-seriosacademy-js" */),
  "component---src-pages-solicitacaoprovadesegundachamada-js": () => import("./../../../src/pages/solicitacaoprovadesegundachamada.js" /* webpackChunkName: "component---src-pages-solicitacaoprovadesegundachamada-js" */),
  "component---src-pages-sucesso-js": () => import("./../../../src/pages/sucesso.js" /* webpackChunkName: "component---src-pages-sucesso-js" */)
}

